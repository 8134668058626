import React, { useState } from "react";
import "./login.css";
import axios from "axios";
import { useNavigate } from "react-router";

const Login: React.FC = () => {
  const [phone, setPhoneNumber] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const handleLogin = async () => {
    setError(null); // Pehle error clear karein

    if (phone.length !== 10 || !/^\d+$/.test(phone)) {
      setError("Please enter a valid 10-digit phone number.");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.React_App_DYO_CHEF_Login_With_otp}`,
        { phone, contryCode: "91",role:'user'},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data.data.otp);
      const responseOTP = response.data.data.otp
      localStorage.setItem('phone',phone)
      navigate("/otp",{state:{phone,responseOTP}})
    } catch (err: any) {
      console.error("Error sending OTP:", err);
      setError(err.response?.data?.message || "Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);

    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <h2>
          Welcome to DYO <span>CHEF</span>
        </h2>
        <div className="login-form">
          <label className="form-label">Enter 10-digit mobile number</label>
          <div className="phone-input-wrapper input-group">
            <h5 className="country-code">+91</h5>
            <input
              type="text"
              className={`form-control login-input login-form-input ${error ? "error-border" : ""}`}
              placeholder="Enter phone number"
              value={phone}
              onChange={(e) => setPhoneNumber(e.target.value)}
              maxLength={10}
            />
          </div>
          {error && <p className="error-text">{error}</p>}
          <button
            className="btn btn-rounded btn-width btn-dyo-primary"
            onClick={handleLogin}
            disabled={phone.length !== 10 || loading}
          >
            {loading ? "Sending OTP..." : "Send OTP"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
