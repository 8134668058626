import "./alert.css";
import { useNavigate } from "react-router-dom";

interface AlertProps {
    message: string;
    onClose: () => void;
  }

  const Alert: React.FC<AlertProps> = ({ message, onClose }) => {

    const navigate = useNavigate()

    if (!message) return null; // Don't render if there's no message

    const handleOkClick = () => {
        onClose(); // Hide Alert
        navigate("/bookingview"); // Navigate to Booking Record screen
      };

  return (
    <div className="alert-wrapper">
      <div className="alert-box">
        <p>
        Your booking has been created with us.<br/> Our team will contact you soon.</p>
        <button onClick={handleOkClick}>OK</button>
      </div>
    </div>
  );
};

export default Alert;
