import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Registration from './pages/Registration/Index';
import Booking from './pages/Booking/CreateBooking';
import MealsScreen from './pages/Meals';
// import AllService from './pages/AllService';
import PartnerRegistration from './pages/ChefRegistration/PersonalDetails/Index';
import WorkingExperince from './pages/ChefRegistration/WorkingExperience/Index';
import Documentation from './pages/ChefRegistration/Document/Index';
import Payment from './pages/Payment/Index';
import Contact from './pages/contact';
import Mybookings from './pages/Booking/BookingView';
import Bookingdetails  from './pages/Booking/BookingDetails';
import Allservice from './pages/AllService';
import { Card } from 'react-bootstrap';
import GeneralBookingDetails from './pages/Booking/journalBookingDetails';
//import allserviceinterface from './pages/AllService/allserviceinterface';
import Userprofile from './pages/userprofile/index';
import PartnerLogin from './pages/ChefLogin';
import PartnerHome from './pages/PartnerDashbord/PartnerHome';
import PartnerBookings from './pages/PartnerDashbord/PartnerBooking';
import PartnerFeedback from './pages/PartnerDashbord/PartnerFeedback';
import UpdateUserProfile from './pages/UpdateUserProfile';
import UserRatings from './pages/Rating/UserRatings';
import Partnerprofile from './pages/PartnerDashbord/PartnerProfile';
import GetUserRating from './pages/Rating/GetUserRating';
import { Payment_gatway } from './components/PaymentGatway';
import Privacy_Policy from "./components/Privacy-Policy";
import Refund_Policy from './components/RefundPolicy';
import Term_and_Condition from './components/Term_and_Condition';
import OTP from './pages/OTP';
import PaymentFailed from './pages/PaymentFailed';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            {/* <Route path='/allservice' element={<AllService allservice={[]}/>} /> */}
            <Route path='/login' element={<Login/>} />
            <Route path='/otp' element={<OTP/>} />
            <Route path='/user/registration' element={<Registration/>} />
            <Route path='/booking' element={<Booking/>} />
            <Route path='/journalbookingdetails' element={<GeneralBookingDetails />} />
            <Route path='/meals' element={<MealsScreen/>} />
            <Route path='/payment' element={<Payment />} />
            <Route path='/payment/failed' element={<PaymentFailed />} />
            
            <Route path='/contact' element={<Contact/>} />
            <Route path='/allservice' element={<Allservice />} />
            <Route path='/primeservice' element={<Card/> } />

            <Route path='/partner/registration' element={<PartnerRegistration/>} />
            <Route path='/partner/login' element={<PartnerLogin/>} />
            <Route path='/chef/working-experince' element={<WorkingExperince/>} />
            <Route path='/chef/documentation' element={<Documentation />} />
            <Route path='/bookingview' element={<Mybookings/>} />
            <Route path='/bookingdetails' element={<Bookingdetails/>} />
            <Route path='/userProfile' element={<Userprofile />} />
            <Route path='/user/profile/update' element={<UpdateUserProfile />} />
            <Route path="/partner/profile" element={<Partnerprofile/>} />
            <Route path='/partner/dashboard' element={<PartnerHome/>} />
            <Route path='/partner/bookings' element={<PartnerBookings/>} />
            <Route path='/partner/feedback' element={<PartnerFeedback />} />
            <Route path='/Payment_gatway' element={<Payment_gatway/>} />
            <Route path='/user/rating' element={<UserRatings/>} />
            <Route path='/review' element={<GetUserRating/>} />
            <Route path='/privacy-policy' element={<Privacy_Policy />} />
            <Route path='/Refund_Policy' element={<Refund_Policy/>} />
            <Route path='/Term_and_Condition' element={<Term_and_Condition />} />
        </Routes>
    );
}
export default AppRoutes;