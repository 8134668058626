// import cardinterface from "./cardinterface";

// const Card = (props: cardinterface) => {

//   const card = props.card;

//   return (
//     <>

//       <div className="seprator-100 discover-section">
//         <div className="container">
//           <div className="section-title">
//             <h2> Discover</h2>
//             <p> MOST FAMOUS FEATURES </p>
//           </div>
//           <div className="discover-inner mt-5">
//             <div className="row" >
//               {card.map((e: any, index) => (
//                 <div key={index} 
//                  className="col-sm-4 d-flex flex-column gap-12  align-items-center" >
//                   <div className="dis-icon">
//                     <img src={e.imageUrl} alt="chefservice" className="img-fluid" />
//                   </div>
//                   <h4>{e.label}</h4>
//                   <p className="text-center px-4">{e.description}</p>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Card;


import { useAppDispatch, useAppSelector } from "../../store/appStore";
import { selectMeal } from "../../store/meals/meals-slice";
import cardinterface from "./cardinterface";
import { useNavigate } from "react-router-dom";

const Card = (props: cardinterface) => {
  const card = props.card;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
   const isAuthenticated = useAppSelector(
      (state) => state.authorizer.isauthenticate
    );
  
    const handleBooking = (meal: string,label:string) => {
      dispatch(selectMeal(meal));
  
      if (isAuthenticated) {
        navigate("/booking",{state:{label}});
      } else {
        localStorage.getItem("accessToken")
          ? navigate("/booking",{state:{label}})
          : navigate("/login");
      }
    };

  return (
    <>
      <div className="seprator-100 discover-section">
        <div className="container">
          <div className="section-title">
            <h2> Discover</h2>
            <p> MOST FAMOUS FEATURES </p>
          </div>
          <div className="discover-inner mt-5">
            <div className="row">
              {card.map((e: any, index) => {
                const isDisabled = index === 1;
                return (
                  <div
                    key={index}
                    className={`col-sm-4 d-flex flex-column gap-12 align-items-center ${isDisabled ? 'disabled-card' : ''}`}
                    style={isDisabled ? { pointerEvents: 'none', opacity: 1 } : {}}
                    onClick={() => {handleBooking("meal",e.label)}}
                  >
                    <div className="dis-icon">
                      <img src={e.imageUrl} alt="chefservice" className="img-fluid" />
                    </div>
                    <h4>{e.label}</h4>
                    <p className="text-center px-4">
                      {isDisabled ? "Coming Soon" : e.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
