import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/appStore";
import { selectMeal } from "../../store/meals/meals-slice";
import { useNavigate } from "react-router";
import Loader from "../../components/Loader";


const Allservice = () => {

  const [allservice, setAllservice] = useState([])
  const [isLoading, setIsLoading] = useState(true)


  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {

    try {
      const data = await fetch("https://dev-oapis-api.dyo.world/api/landing?page=1")
      const json = await data.json()
      setIsLoading(false);
      setAllservice(json.data.services);

    } catch (error) {
      console.log("API NOT FETCH", error)
    }
  }

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state) => state.authorizer.isauthenticate);

  const handleBooking = (meal: string,requirement:any) => {
    dispatch(selectMeal(meal));
    console.log('re',requirement)
    console.log(meal)
    if (isAuthenticated) {
      navigate('/booking', { state: { label: "One Time Chef",requirement} });
    } else {
      localStorage.getItem('accessToken') ? navigate('/booking', { state: { label: "One Time Chef",requirement} }) : navigate('/login');
    }
  };


  return (
    <>
      <div className="text-center">{isLoading && <Loader/>}
      </div>
      {
        <div className="seprator-100 quick-book-section">
          <div className="container">
            <div className="section-title">
              <h2>Quick Book</h2>
              <p>Our Services</p>
            </div>
            <div className="container">
              <div className="row mt-5">

                {allservice.map((e: any, index) => (
                  <div className="col-sm-6 mb-3" key={index}>
                    <div className="border d-flex g-1 align-items-center service-card">
                      <div className="quick-book-iteams">
                        <h6>{e.label}</h6>
                        <p>
                          {e.description}
                        </p>
                      </div>
                      <button
                        className="service-btn"
                        type="button"
                        // className="btn-dyo-secondary  btn-rounded bg-body"
                        onClick={() => { handleBooking('meal',e.requirement) }}
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default Allservice;