
import { selectMeal } from "../../store/meals/meals-slice";
import { useAppDispatch, useAppSelector } from "../../store/appStore";
import { useNavigate } from "react-router";

const Carousel = () => {

  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state) => state.authorizer.isauthenticate);


  const handleBooking = (meal: string) => {
    dispatch(selectMeal(meal));

    if (isAuthenticated) {
      navigate('/booking', { state: { label: "One Time Chef"} });
    } else {
      localStorage.getItem('accessToken') ? navigate('/booking', { state: { label: "One Time Chef"} }) : navigate('/login')
    }
  }

  const handleservice = () => {
    handleBooking('meal');
  }

  return (
    <>
      <div className="hero">
      <section className="home" id="home">
        <div className="content">
          <h3>Whipping Up Flavors That'll Steal <span className="high-lite-line"> Your Heart!</span></h3>
          <p>Book a <span>chef</span>  for a special occasion or a regular weekly meal plan.</p>
          <button className="btn-dyo-banner" onClick={() => { handleservice() }}>Book Now</button>
        </div>
      </section>
      </div>
    </>
  );
};

export default Carousel;
