import { Link } from "react-router-dom";
import axios from 'axios';
import "./payment-gatway.css";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "../Alert";
import { useState } from "react";

const SALT_KEY = "6f6236bd-6d57-4ea8-9f55-3bca14fa3f6c";
const MERCHANT_DATA = {
    "merchantId": "M22F1UQL3OQ50",
    "merchantTransactionId": "5619f29c-1587-4664-8393-0d43fe8d7527",
    "merchantUserId": "ea19ff36-fd4e-47a9-88dc-bcdbaed18f94",
    "amount": 100,
    "redirectUrl": "https://chef.dyo.world/allservice",
    "mobileNumber": "9039435515",
    "paymentInstrument": {
        "type": "PAY_PAGE"
    }
}

const saltIndex = 1;
const generateXVerifyHeader = async (payload: any, saltKey: string, saltIndex: any) => {
    // Step 1: Base64 encode the payload  
    const base64Payload = btoa(JSON.stringify(payload));
    console.log("base64Payload", base64Payload)

    // Step 2: Prepare the string to hash  
    const stringToHash = base64Payload + "/pg/v1/pay" + saltKey;

    // Step 3: Create SHA256 hash  
    const encoder = new TextEncoder();
    const data = encoder.encode(stringToHash);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);

    // Convert the hash ArrayBuffer to hex string  
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

    // Step 4: Construct the final X-Verify header  
    const xVerifyHeader = `${hashHex}###${saltIndex}`;
    //const xVerifyHeader = SHA256(base64Payload+ "/pg/v1/pay" + saltKey) + '###' + saltIndex

    return { xVerifyHeader, base64Payload };
}

export const Payment_gatway = () => {
    const {state} = useLocation();
    console.log(state);
    const {bookingData:{booking:{finalFare, id}}} = state;

    const [alertMessage, setAlertMessage] = useState("");

    const calculateAmountToPay = (amount: number): number => {
        return amount * 0.25;
    };

    const amountToPay = calculateAmountToPay(finalFare);

    const navigate = useNavigate();

    // const handleClick = async () => {
    //     const newMerchantData = { ...MERCHANT_DATA };
    //     newMerchantData.merchantTransactionId = id
    //     newMerchantData.merchantUserId = window.localStorage.getItem("id") || "";
    //     const { xVerifyHeader, base64Payload } = await generateXVerifyHeader(MERCHANT_DATA, SALT_KEY, saltIndex)
    //     const options = {
    //         method: 'post',
    //         //url: 'https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay', //sandbox
    //         url: 'https://api.phonepe.com/apis/hermes/pg/v1/pay', //prod
    //         headers: {
    //             'accept': 'application/json',
    //             'Content-Type': 'application/json',
    //             'X-VERIFY': xVerifyHeader
    //         },
    //         data: {
    //             request: base64Payload
    //         }
    //     };

    //     const response = await axios
    //         .request(options)
    //         .then(function (response) {
    //             console.log(response.data);
    //             const {data: {instrumentResponse:{redirectInfo:{url}}}} = response.data;
    //             navigate(url);
    //         })
    //         .catch(function (error) {
    //             console.error(error);
    //         });
    //         console.log("m..m",response)
    //     //navigate("/payment");
    // };

    const handleClick = async () => {
        try {
            const newMerchantData = { ...MERCHANT_DATA };
            newMerchantData.merchantTransactionId = id;
            newMerchantData.merchantUserId = window.localStorage.getItem("id") || "";
            
            const { xVerifyHeader, base64Payload } = await generateXVerifyHeader(MERCHANT_DATA, SALT_KEY, saltIndex);
            
            const options = {
                method: 'post',
                url: 'https://api.phonepe.com/apis/hermes/pg/v1/pay', //prod
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-VERIFY': xVerifyHeader
                },
                data: {
                    request: base64Payload
                }
            };
    
            const response = await axios.request(options);
            console.log(response.data);
    
            if (response.data.success) {
                const { data: { instrumentResponse: { redirectInfo: { url } } } } = response.data;
                navigate(url);
            } else {
                setAlertMessage("your booking create with us ,we team will contect you");
            }
        } catch (error) {
            console.error("API Error:", error);
            setAlertMessage("your booking create with us ,we team will contect you");
        }
    };

    return (
        <>
            <div>
            {alertMessage && <Alert message={alertMessage} onClose={() => setAlertMessage("")} />}
                <Link
                    to="/journalbookingdetails"
                    className="back-button d-flex ms-3 mt-3 "
                >
                    <i className="fa fa-angle-left me-3"></i>
                    <h5 className="m-0">Payment</h5>
                </Link>
                <div className="payment-gatway">
                    <div className="payment-iteams">
                        <h6>Pay Now</h6>
                        <p>Please pay 25% of the total amount to conform your booking </p>
                        <div className="payment-amount">
                            <i className="fa fa-rupee"></i>
                            <h2>{amountToPay}</h2>
                        </div>
                        <button className="pay-button" onClick={handleClick}>
                            Pay Now
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};