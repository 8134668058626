import { useEffect, useState } from "react";
import { useLocation,useNavigate } from "react-router";
import Popover from "../../../components/PopoverButton";
import "./booking.css";
import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

const timeOptions = [
  "8.00 am",
  "9.00 am",
  "10.00 am",
  "11.00 am",
  "12.00 pm",
  "1.00 pm",
  "2.00 pm", 
  "3.00 pm",
  "4.00 pm",
  "5.00 pm",
  "6.00 pm",
  "7.00 pm",
  "8.00 pm",
  "9.00 pm",
  "10.00 pm",
];

function CreateBookings() {
  const [selectedRequirement, setSelectedRequirement] = useState<string>("");
  const [selectedMeals, setSelectedMeals] = useState<string[]>([]);
  const [selectedAdditionals, setSelectedAdditionals] = useState<string[]>([]);
  // const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [booking, setBooking] = useState({
    startTime: "",
    startDate: "",
    headCount: 4,
    endDate: "",
    endTime: "",
  });
  const [numberOfStarters, setStarters] = useState(2);
  const [numberOfMainCourses, setMainCourse] = useState(5);
  const [numberOfBreakfast, setBreakfast] = useState(3);

  const [fare, setFare] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const token = localStorage.getItem("accessToken");
  const location = useLocation();
  const navigate = useNavigate();

  const { label,requirement } = location.state;
  console.log('label',label,requirement)
  useEffect(() => {
    const fetchData = async () => {
      const formData = {
        requirement: selectedRequirement,
        meals: selectedMeals,
        additionalServices: selectedAdditionals,
        ...booking,
        numberOfMainCourses,
        numberOfStarters,
        numberOfBreakfast,
      };

      if (
        selectedMeals.includes("lunch") ||
        selectedMeals.includes("dinner") ||
        selectedMeals.includes("breakfast") ||
        selectedMeals.includes("starters")
      ) {
        if (
          selectedMeals.includes("lunch") ||
          selectedMeals.includes("dinner")
        ) {
          formData.numberOfMainCourses = numberOfMainCourses;
        }
        if (selectedMeals.includes("starters")) {
          formData.numberOfStarters = numberOfStarters;
        }
        if (selectedMeals.includes("breakfast")) {
          formData.numberOfBreakfast = numberOfBreakfast;
        }
      }

      console.log(formData);

      try {
        const response = await fetch(
          `${process.env.React_App_DYO_CHEF_Fare_Calculation_URL}`,
          {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
              "Contact-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        let data = await response.json();
        console.log('status',response.status)
        if(response.status === 200){
          setFare(data?.data?.fare);
          setTotalAmount(data?.data?.fare?.totalPrice)
        }else if(response.status === 401){
            navigate('/login')
        }else{
          console.log("error")
        }
      } catch (error) {
        console.log("Api not response", error);
      }
    };
    fetchData();
  }, [
    selectedRequirement,
    selectedMeals,
    selectedAdditionals,
    booking,
    // fare,
    numberOfMainCourses,
    numberOfBreakfast,
    numberOfStarters,
  ]);

  useEffect(() => {
    if (requirement) {
      setSelectedRequirement(requirement);
    }
  }, [requirement]);


  const handleRequirementClick = (value: string) => {
    setSelectedRequirement(value);
  };

  const handleMealClick = (value: string) => {
    setSelectedMeals((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  const isLunchSelected = selectedMeals.includes("lunch");
  const isBreakfastSelected = selectedMeals.includes("breakfast");
  const isDinnerSelected = selectedMeals.includes("dinner");
  const isStarterSelected = selectedMeals.includes("starters");

  const showMainCourse = isLunchSelected || isDinnerSelected;
  // 'dinner', 'breakfast', 'snacks', 'lunch'
  const handleAdditionalClick = (value: string) => {
    if (selectedAdditionals.includes(value)) {
      setSelectedAdditionals(
        selectedAdditionals.filter((item) => item !== value)
      );
    } else {
      setSelectedAdditionals([...selectedAdditionals, value]);
    }
  };

  const [validationError, setValidationError] = useState("");

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setBooking((prevBooking) => {
      const updatedBooking = {
        ...prevBooking,
        [name]: value,
      };

      if (name === "startDate" && prevBooking.endDate < value) {
        updatedBooking.endDate = value;
      }

      if (name === "startTime") {
        // Automatically set the end time based on the start time
        const endTimeIndex =
          timeOptions.findIndex((time) => time === value) + 4;
        updatedBooking.endTime = timeOptions[endTimeIndex] || "4";
      }

      validateTimes(
        updatedBooking.startDate,
        updatedBooking.endDate,
        updatedBooking.startTime,
        updatedBooking.endTime
      );
      return updatedBooking;
    });
  };

  const convertTo24HourFormat = (time: any) => {
    return moment(time, ["h:mm A"]).format("HH:mm:ss");
  };

  const validateTimes = (
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any
  ) => {
    if (startDate && endDate && startTime && endTime) {
      const start = convertTo24HourFormat(startTime);
      const end = convertTo24HourFormat(endTime);
      const startDateTime = moment(
        startDate + " " + start,
        "YYYY-MM-DD HH:mm:ss"
      );
      const endDateTime = moment(endDate + " " + end, "YYYY-MM-DD HH:mm:ss");

      if (start !== "00:00:00" && end !== "00:00:00") {
        if (startDateTime.isSameOrAfter(endDateTime)) {
          setValidationError(
            "Start date and time must be before end date and time."
          );
          return false;
        }
      }
    }
    setValidationError("");
    return true;
  };

  const handleDecrement = () => {
    if (booking.headCount > 4) {
      setBooking({ ...booking, headCount: booking.headCount - 1 });
    }
  };

  const handleIncrement = () => {
    if (booking.headCount < 25) {
      setBooking({ ...booking, headCount: booking.headCount + 1 });
    }
  };

  const StartersDecrement = () => {
    if (numberOfStarters > 0) {
      setStarters(numberOfStarters - 1);
      console.log("startters", numberOfStarters);
    }
  };

  const StartersIncrement = () => {
    if (numberOfStarters < 8) {
      setStarters(numberOfStarters + 1);
    }
  };

  const MainCoursesDecrement = () => {
    if (numberOfMainCourses > 0) {
      setMainCourse(numberOfMainCourses - 1);
    }
  };

  const MainCoursesIncrement = () => {
    if (numberOfMainCourses < 8) {
      setMainCourse(numberOfMainCourses + 1);
    }
  };

  const BreakfastDecrement = () => {
    if (numberOfBreakfast > 0) {
      setBreakfast(numberOfBreakfast - 1);
    }
  };

  const BreakfastIncrement = () => {
    if (numberOfBreakfast < 8) {
      setBreakfast(numberOfBreakfast + 1);
    }
  };

  const handleSubmit = async () => {
    // setIsSubmitDisabled(true);

    if (!selectedRequirement) {
      toast("Please select requirement");
      // setIsSubmitDisabled(false);
      return;
    } else if (selectedMeals.length === 0) {
      toast("Please select meals");
      // setIsSubmitDisabled(false);
      return;
    } else if (!booking.startTime) {
      toast("Please select time");
      // setIsSubmitDisabled(false);
      return;
    } else if (!booking.startDate) {
      toast("Please select date");
      // setIsSubmitDisabled(false);
      return;
    }

    if (
      (selectedMeals.includes("lunch") || selectedMeals.includes("dinner")) &&
      numberOfMainCourses === 0
    ) {
      toast("Please select main course");
      // setIsSubmitDisabled(false);
      return;
    }

    if (selectedMeals.includes("breakfast") && numberOfBreakfast === 0) {
      toast("Please select breakfast");
      // setIsSubmitDisabled(false);
      return;
    }

    if (selectedMeals.includes("starters") && numberOfStarters === 0) {
      toast("Please select starters");
      // setIsSubmitDisabled(false);
      return;
    }

    const formData = {
      requirement: selectedRequirement,
      meals: selectedMeals,
      additionalServices: selectedAdditionals || [],
      ...booking,
    };

    console.log("234567890-rtyuiop", JSON.stringify(formData));
    console.log("fare ",totalAmount)
    navigate("/journalbookingdetails", {
      state: { bookingData: formData, userData: fare, totalAmount },
    });
  };

  return (
    <>
      <div className="booking-getway-wrapper">
        <div className="booking-getway-inner">
          <div className="booking-getway-inner-items">
            <div className="d-flex ">
              <Link to="/" className="back-button">
                <i className="fa fa-angle-left me-2"></i>
              </Link>
              <h2 className="spacing-head">
                {label}
              </h2>
            </div>

            <div className="form-group mb-2 seprator-52">
              <label className="form-label booking-field">
                Select Requirement
              </label>
              <div>
                <button
                  value="birthday"
                  onClick={() => handleRequirementClick("birthday")}
                  className={
                    selectedRequirement === "birthday"
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="requirement"
                >
                  Birthday
                </button>
                <button
                  value="kitty"
                  onClick={() => handleRequirementClick("kitty")}
                  className={
                    selectedRequirement === "kitty"
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="requirement"
                >
                  Kitty
                </button>
                <button
                  value="occasion"
                  onClick={() => handleRequirementClick("occasion")}
                  className={
                    selectedRequirement === "occasion"
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="requirement"
                >
                  Get together
                </button>
                <button
                  value="Gathering"
                  onClick={() => handleRequirementClick("gathering")}
                  className={
                    selectedRequirement === "gathering"
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="requirement"
                >
                  Gathering
                </button>
                <button
                  value="other"
                  onClick={() => handleRequirementClick("other")}
                  className={
                    selectedRequirement === "other"
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="requirement"
                >
                  Other
                </button>
              </div>
            </div>
            <div className="form-group mb-2">
              <label className="form-label booking-field">Select Meal</label>
              <div>
                <button
                  value="breakfast"
                  onClick={() => handleMealClick("breakfast")}
                  className={
                    selectedMeals.includes("breakfast")
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="meals"
                >
                  Breakfast
                </button>
                <button
                  value="lunch"
                  onClick={() => handleMealClick("lunch")}
                  className={
                    selectedMeals.includes("lunch")
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="meals"
                >
                  Lunch
                </button>
                <button
                  value="starters"
                  onClick={() => handleMealClick("starters")}
                  className={
                    selectedMeals.includes("starters")
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="meals"
                >
                  Starters
                </button>
                <button
                  value="dinner"
                  onClick={() => handleMealClick("dinner")}
                  className={
                    selectedMeals.includes("dinner")
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="meals"
                >
                  Dinner
                </button>
              </div>
            </div>

            <div className="form-group mb-2">
              <label className="form-label fw-medium m-0">No of people</label>
              <p className="booking-note">Contact to select more than 20</p>
              <div className="head-count-wrapper">
                <button className="minus" onClick={handleDecrement}>
                  -
                </button>
                <span className="num">{booking.headCount}</span>
                <button className="plus" onClick={handleIncrement}>
                  +
                </button>
              </div>
            </div>

            {isBreakfastSelected && (
              <div className="form-group mb-2 d-flex justify-content-between menu-frame">
                <div>Number of Breakfast</div>
                <div className="head-count-wrapper">
                  <button className="minus" onClick={BreakfastDecrement}>
                    -
                  </button>
                  <span className="num">{numberOfBreakfast}</span>
                  <button className="plus" onClick={BreakfastIncrement}>
                    +
                  </button>
                </div>
              </div>
            )}
            {isStarterSelected && (
              <div className="form-group mb-2 d-flex justify-content-between menu-frame">
                <div>Number of Starters</div>
                <div className="head-count-wrapper">
                  <button className="minus" onClick={StartersDecrement}>
                    -
                  </button>
                  <span className="num">{numberOfStarters}</span>
                  <button className="plus" onClick={StartersIncrement}>
                    +
                  </button>
                </div>
              </div>
            )}

            {showMainCourse && (
              <div className="form-group mb-2 d-flex justify-content-between menu-frame">
                <div>Number of Main courses</div>
                <div className="head-count-wrapper">
                  <button className="minus" onClick={MainCoursesDecrement}>
                    -
                  </button>
                  <span className="num">{numberOfMainCourses}</span>
                  <button className="plus" onClick={MainCoursesIncrement}>
                    +
                  </button>
                </div>
              </div>
            )}

            <div className="row mt-3">
              <div className="col-md-6">
                <label className="form-label">Start Date</label>
                <div className="d-flex">
                  <input
                    className="form-control date-merge booking-form-date-input"
                    type="date"
                    id="date"
                    name="startDate"
                    value={booking.startDate}
                    onChange={handleChange}
                    min={new Date().toISOString().split("T")[0]}
                    required
                  />
                  <div className="mx-1">
                    <select
                      id="startTime"
                      className="booking-form-time-input"
                      name="startTime"
                      value={booking.startTime}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        Select Start Time
                      </option>
                      {timeOptions.map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label className="form-label">End Date</label>
                <div className="d-flex">
                  <div className="form-control date-merge booking-form-date-input" >
                      <p className="m-0" >{booking.endDate ? moment(booking.endDate).format('MM/DD/YYYY') :"mm/dd/yy"}</p>
                  </div>
                  {/* <input
                    className="form-control date-merge booking-form-date-input"
                    type="date"
                    id="date"
                    name="endDate"
                    value={booking.endDate}
                    onChange={handleChange}
                    min={
                      booking.startDate
                        ? booking.startDate
                        : new Date().toISOString().split("T")[0]
                    }
                    required
                  /> */}
                  <div className="mx-1">
                    <select
                      id="endTime"
                      className="booking-form-time-input"
                      name="endTime"
                      value={booking.endTime}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        Select End Time
                      </option>
                      {timeOptions.map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {validationError && (
                <div className="col-12 mt-2">
                  <div className="toast toast-danger">{validationError}</div>
                </div>
              )}
            </div>

            <div className="form-group mt-2">
              <label className="form-label booking-field mt-1">
                Additional Requirements
              </label>
              <div>
                <button
                  value="cleaner"
                  onClick={() => handleAdditionalClick("cleaner")}
                  className={
                    selectedAdditionals.includes("cleaner")
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="additionalServices"
                >
                  Cleaner
                </button>
                <button
                  value="waiter"
                  onClick={() => handleAdditionalClick("waiter")}
                  className={
                    selectedAdditionals.includes("waiter")
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="additionalServices"
                >
                  Waiter
                </button>
                {/* <button
                  value="cutlery"
                  onClick={() => handleAdditionalClick("cutlery")}
                  className={
                    selectedAdditionals.includes("cutlery")
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="additionalServices"
                >
                  Cutlery
                </button> */}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center booking-bottom-section ">
            <h3 className="mt-2 d-flex align-items-center">
              <i className="fa fa-rupee"></i>
              {totalAmount}
              <Popover selectedMeals={selectedMeals} placement="bottom" />
            </h3>
            <button className="booking-button" onClick={handleSubmit}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateBookings;
